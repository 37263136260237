import styles from "./styles.module.scss";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { customImageLoader, removePhoneMask } from "../../core-nextv3/util/util";
import Image from "next/image";

export const WhatsAppIcon = ({ account }: any) => {
  return (
    <div
      className={styles.whatsAppIcon}
      onClick={() =>
        {
          tagManager4.registerEvent("contact", "whatsapp-button", "Contato", 0, null);
          window.open(
            `https://api.whatsapp.com/send?phone=${removePhoneMask(account?.whatsapp)}&text=Olá, gostaria de tirar algumas duvidas.`,
            "_blank"
          )
        }
      }
    >
      <Image
          width={20}
          height={20}
          src="/assets/icons/BsWhatsapp.svg"
          alt=""
          loader={customImageLoader}
      />
    </div>
  );
};
