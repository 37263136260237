import "../styles/global.scss";
import '@splidejs/react-splide/css'; 

import { AppPage } from "../varejo-nextv3/AppPage";
import { AppPageTwo } from "../varejo-nextv3/AppPageTwo";
import { WhatsAppIcon } from "@/component-nextv3/WhatsAppIcon";

function MyApp({ Component, pageProps }: any) 
{
	return (
		// <AppPageTwo Component={Component} pageProps={pageProps} />	
		<>
			<Component {...pageProps} />
			<WhatsAppIcon account={pageProps.account} />
		</>
	);
}

export default MyApp; 
 